// @flow

import * as React from 'react'
import cx from 'classnames'

import {Link} from 'gatsby'

import {Portal} from '../portal'
import {
  Button,
  ContactUs,
  InlineHubspotButton,
  addGtmTags,
} from '../../ui-components'

import type {ButtonProps} from '../../ui-components'

import * as styles from './GetInTouch.module.css'

export type GetInTouchProps = {
  /** Required heading title message */
  title: string,
  /** Optional secondary message */
  secondaryTitle?: string,
  /** If true, show email button */
  email?: boolean,
  /** If true, show demo button */
  demo?: boolean,
  /** If true, show intercom button */
  chat?: boolean,
  /** Optional className override */
  className?: string,
  /** If true, white background with dark text/buttons */
  invert?: boolean,
  /** Optional custom button to render instead of defaults */
  buttons?: Array<ButtonProps>,
  /** GetInTouch one off */
  centeredContent?: boolean,
  /* hubspotDemo */
  hubspotDemo?: {demoPortalId: string, demoFormId: string},
  portalId?: string,
  formId?: string,
  gtmCategory?: string,
  gtmLabelEmail?: string,
  gtmLabelDemo?: string,
  gtmLabelChat?: string,
}

export default function GetInTouch(props: GetInTouchProps) {
  const [showEmailModal, setShowEmailModal] = React.useState<boolean>(false)
  const {
    email,
    demo,
    chat,
    invert,
    buttons,
    centeredContent,
    portalId,
    formId,
    hubspotDemo,
    gtmCategory,
    gtmLabelEmail,
    gtmLabelDemo,
    gtmLabelChat,
  } = props
  const contactClass = cx(styles.get_in_touch, props.className, {
    [styles.invert]: invert,
  })
  const HUBSPOT_CATEGORY = 'b-ot2'
  const HUBSPOT_LABEL = 'contact1-demo-button'

  React.useEffect(() => {
    addGtmTags(HUBSPOT_CATEGORY, HUBSPOT_LABEL)
  }, [])

  return (
    <>
      {showEmailModal && (
        <Portal>
          <ContactUs
            portalId={portalId}
            formId={formId}
            onCloseClick={() => setShowEmailModal(false)}
          />
        </Portal>
      )}
      <div className={contactClass}>
        <section
          className={cx(styles.section, {
            [styles.centered_content]: centeredContent,
          })}
        >
          <div
            className={cx(styles.title_div, {
              [styles.centered_content_text]: centeredContent,
            })}
          >
            <h3
              className={cx(styles.title_h3, {
                [styles.invert]: invert,
              })}
            >
              {props.title}
            </h3>
            <h5
              className={cx(styles.title_h5, {
                [styles.invert]: invert,
              })}
            >
              {props.secondaryTitle}
            </h5>
          </div>
          <div
            className={cx(styles.button_div, {
              [styles.button_div_center]: centeredContent,
              [styles.hubspot_button]: hubspotDemo,
            })}
          >
            {hubspotDemo && (
              <div>
                <InlineHubspotButton
                  portalId={hubspotDemo.demoPortalId}
                  formId={hubspotDemo.demoFormId}
                />
              </div>
            )}
            {email && (
              <Button
                className={cx(styles.button, {
                  [styles.centered_content_button]: centeredContent,
                })}
                white={!invert}
                Component={Link}
                to="/contact"
                gtmCategory={gtmCategory}
                gtmLabel={gtmLabelEmail}
                gtmAction={'click'}
              >
                email us
              </Button>
            )}

            {demo && (
              <Button
                className={cx(styles.button, {
                  [styles.centered_content_button]: centeredContent,
                })}
                white={!invert}
                Component="a"
                linkOutProps={{
                  url: 'https://insights.opentrons.com/schedule-demo/',
                  gtm: {
                    action: 'click',
                    category: gtmCategory,
                    label: gtmLabelDemo,
                  },
                }}
              >
                schedule demo
              </Button>
            )}

            {chat && (
              <a
                href="http://support.opentrons.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className={cx(styles.button, styles.margin_left, {
                    [styles.centered_content_button]: centeredContent,
                  })}
                  white={!invert}
                  gtmCategory={gtmCategory}
                  gtmLabel={gtmLabelChat}
                  gtmAction={'click'}
                >
                  chat with us
                </Button>
              </a>
            )}
            {buttons &&
              buttons.map((button, i) => {
                return <Button {...button} key={i} />
              })}
          </div>
        </section>
      </div>
    </>
  )
}
