// @flow
import * as React from 'react'
import ReactDom from 'react-dom'

type Props = {children: React.Node}

type State = {hasRoot: boolean}

const PORTAL_ROOT_ID = 'portal'
const getPortalRoot = () => document.getElementById(PORTAL_ROOT_ID)

export function PortalRoot() {
  return <div id={PORTAL_ROOT_ID} />
}

// the children of Portal are rendered into the PortalRoot if it exists in DOM
export class Portal extends React.Component<Props, State> {
  $root: ?Element

  constructor(props: Props) {
    super(props)
    this.$root = getPortalRoot()
    this.state = {hasRoot: !!this.$root}
  }

  componentDidMount() {
    if (!this.$root) {
      this.$root = getPortalRoot()
      this.setState({hasRoot: !!this.$root})
    }
  }

  render() {
    if (!this.$root) return null
    return ReactDom.createPortal(this.props.children, this.$root)
  }
}
