import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import RequestProtocol from '../components/RequestProtocol'

const RequestProtocolPage = () => (
  <Page>
    <SEO
      title="Request a Custom Protocol | Opentrons Lab Automation $5,000"
      description="Ready for Opentrons in your lab? Labs in 40+ countries trust Opentrons to help automate their workflows. We offer 60-day risk-free returns & flexible payment options. "
    />
    <RequestProtocol />
  </Page>
)

export default RequestProtocolPage
