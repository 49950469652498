// @flow

import * as React from 'react'
import HubspotForm from 'react-hubspot-form'

import GetInTouch from '../GetInTouch'

import * as styles from './RequestProtocol.module.css'

import {
  HS_PORTAL_ID,
  HS_FORM_ID,
} from '../../ui-components/contact-us/contact-us-data'

export default function RequestProtocol() {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.h2}>
          Schedule a Protocol Development Consultation
        </h2>
        <h5 className={styles.h5}>
          Schedule a 20 minute call with our protocol specialists to discuss
          your workflow needs! Our team will guide you to the protocol option
          that is best for you.
        </h5>
      </div>

      <div className={styles.bg_color}>
        <h3 className={styles.h3}>
          Opentrons Protocol Development Consultation
        </h3>
        <p className={styles.h5}>
          {`Want to discuss custom protocol development?\nFill out the form below.`}
        </p>
        <HubspotForm
          portalId={HS_PORTAL_ID}
          formId={HS_FORM_ID}
          onSubmit={() => console.log('Submit!')}
          onReady={form => console.log('Form ready!')}
          loading={<div>Loading...</div>}
          cssClass={styles.hubspot_form}
          submitButtonClass={styles.hubspot_button}
        />
      </div>

      <GetInTouch
        centeredContent={true}
        title="Ready for Opentrons in your lab?"
        chat
        email
        gtmCategory={'cta-2'}
        gtmLabelEmail={'email-us'}
        gtmLabelChat={'chat-with-us'}
      />
    </>
  )
}
